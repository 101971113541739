<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Korporativ karta registratsiya</h3>
          </div>
        </div>

        <div class="card-body">
          <v-col cols="6" :class="'mx-auto'">
            <form>
              <v-autocomplete
                v-model="owner_name"
                :items="getAllStaff"
                :error-messages="ownernameErrors"
                @input="$v.owner_name.$touch()"
                @blur="$v.owner_name.$touch()"
                label="Ism Familiya"
                item-text="full__name"
                item-value="id"
                dense
                outlined
              >
              </v-autocomplete>

              <v-text-field
                v-model="number_16_digit"
                label="Karta raqami"
                maxlength="19"
                :error-messages="gidit16Errors"
                v-mask="'#### #### #### ####'"
                @input="$v.number_16_digit.$touch()"
                @blur="$v.number_16_digit.$touch()"
                required
                dense
                outlined
              ></v-text-field>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
                :class="'mb-5'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Amal qilish sanasi"
                    append-icon="event"
                    readonly
                    :value="mmyycard | dateFilter"
                    v-bind="attrs"
                    dense
                    outlined
                    :error-messages="cardError"
                    @input="$v.mmyycard.$touch()"
                    @blur="$v.mmyycard.$touch()"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="mmyycard" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn small color="error" @click="modal = false"
                    >Bekor qilish</v-btn
                  >
                  <v-btn small color="success" @click="$refs.dialog.save(date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>

              <v-btn
                class="mr-4 w-100"
                color="success"
                :disabled="coCardLoading"
                @click="submit"
                ><i v-if="coCardLoading" class="el-icon-loading"></i>
                Yuborish</v-btn
              >
            </form>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'

export default {
  mixins: [validationMixin],
  validations: {
    number_16_digit: { required },
    owner_name: { required },
    mmyycard: { required }
  },
  data() {
    return {
      coCardLoading: false,
      expireDate: '',
      name: '',
      owner_name: '',
      mmyycard: new Date().toISOString().substr(0, 10),
      owner_truck: '',
      number_16_digit: '',
      remain: '',
      menu: false,
      modal: false,
      menu2: false
    }
  },
  // Action
  beforeCreate() {
    // this.$store.dispatch("getAllDrivers");
    this.$store.dispatch('getAllStaff')
  },

  computed: {
    getAllStaff() {
      const data = this.$store.state.requests.allStaff
      data.forEach((element) => {
        if (element.staffpersonal_description.father_name == null) {
          element.staffpersonal_description.father_name = ''
        }

        element.full__name =
          element.staffpersonal_description.first_name +
          ' ' +
          element.staffpersonal_description.last_name +
          ' ' +
          element.staffpersonal_description.father_name
      })
      return data
    },
    getAllDriverNames() {
      return this.$store.state.requests.allDrivers
    },
    gidit16Errors() {
      const errors = []
      if (!this.$v.number_16_digit.$dirty) return errors
      !this.$v.number_16_digit.required &&
        errors.push('Karta raqami toldirilishi shart.')
      return errors
    },
    cardError() {
      const errors = []
      if (!this.$v.mmyycard.$dirty) return errors
      !this.$v.mmyycard.required &&
        errors.push('Amal qilish sanasi toldirilishi shart.')
      return errors
    },
    ownernameErrors() {
      const errors = []
      if (!this.$v.owner_name.$dirty) return errors
      !this.$v.owner_name.required &&
        errors.push('Ism Fimiliya toldirilishi shart.')
      return errors
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Korporativ karta registratsiya' }
    ])
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          number_16_digit: this.number_16_digit.replace(/\s/g, ''),
          expire: this.mmyycard,
          owner: this.owner_name
        }
        this.coCardLoading = true
        this.$store
          .dispatch('createConCard', data)
          .then(() => {
            this.coCardLoading = false
          })
          .catch((err) => {
            this.coCardLoading = false
            console.error(err)
          })
        this.$v.$reset()
      }
    }
  }
}
</script>

<style scoped>
.v-list--dense /deep/ .v-list-item .v-list-item__title {
  font-size: 1.2rem !important;
}
</style>
